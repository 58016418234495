import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import iconFileDelete from "../../../assets/media/icon-file-delete.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
import iconCaretDown from "../../../assets/media/icon-caret-down.svg";
import Avatar01 from "../../../assets/media/avatar-01.png";

import Navbar from "../../navbar";
import Header from "../../header";

import { useDispatch, useSelector } from "react-redux";
import {
  addEmployee,
  deleteEmployee,
} from "../../../store/reducers/employeeSlice.js";
import { fetchEmployee } from "../../../store/reducers/employeeSlice.js";
import Modal from "../../modal/Modal";
import classes from "./style.module.css";
import Pagination from "../dashboard/appPagination";
import userImagePlaceholder from "../../../assets/media/avatar2.jpg";
import { deleteUser, getallUser } from "../../../store/reducers/userSlice.js";

const AppEmployees = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const user = useSelector((state) => state.auth.user);
  const employee = useSelector((state) => state.employee);
  const createLoading = useSelector((state) => state.employee.createLoading);
  const fetchLoading = useSelector((state) => state.employee.fetchLoading);
  const [selectedUser, setSelectedUser] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteComfirmModal, setShowDeleteComfirmModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredemployee, setFilteredEmplyee] = useState([]);
  const getallUserdata = useSelector((state) => state.userdata.getalluserdata);

  const navigate = useNavigate();

  const show = (item) => {
    console.log({ item });
    setModalContent(item);
    setShowModal(true);
  };
  const close = () => {
    setModalContent({});
    setShowModal(false);
  };

  const users = employee?.users;
  const authToken = user.access_token;

  const initialValues = {
    delete_message: "",
  };

  const validationSchema = Yup.object({
    delete_message: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // console.log({
      //     values: {
      //         ...values,
      //         ...selectedUser
      //     }
      // })
      setFieldValue("delete_message", "");
      // setShowDeleteComfirmModal(true)
      dispatch(
        deleteEmployee({
          id: selectedUser.id,
          ...values,
          setShowDeleteComfirmModal,
        })
      );
      // dispatch(addEmployee({
      //     delete_message: {
      //         full_name: values.full_name,
      //         national_id_number: values.national_id_number,
      //         email: values.email,
      //         phone: values.phone,
      //     },
      //     accessToken: authToken,
      // }));
    },
  });

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldError,
    setFieldValue,
  } = formik;
  const closeDeleteModal = () => {
    console.log({ errors });
    setFieldError("delete_message", "");
    setShowDeleteModal(false);
  };

  const filtered = (search = "") => {
    const finalArray = users?.filter((item) => {
      for (const key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(search.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });

    setData(finalArray);
    return finalArray;
    // setData(filteredData)
    // return filteredData
  };

  const getTotalPages = () => {
    if (Number.isInteger(data?.length / 10)) return data?.length / 10;
    return Math.floor(data?.length / 10) + 1;
  };
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    filtered();
  }, [users]);
  useEffect(() => {
    const performSearch = async () => {
      const dataSearch = await filtered(searchTerm);
      setData(dataSearch);
    };
    const debounceTimer = setTimeout(() => {
      performSearch();
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  useEffect(() => {
    dispatch(fetchEmployee({ accessToken: authToken }));
    dispatch(getallUser(user.access_token));
  }, [authToken, dispatch]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    setFilteredEmplyee(
      getallUserdata
        .filter((item) => {
          return (
            item.is_deleted != true &&
            item?.user_role !== "company_admin" &&
            !item?.is_admin &&
            Object.values(item).some(
              (value) =>
                value &&
                value
                  .toString()
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
            )
          );
        })
        .sort((a, b) => b.id - a.id)
    );
  }, [getallUserdata, searchQuery]);

  console.log(filteredemployee, getallUserdata);
  const deleteuserdata = (id) => {
    const config = {
      accessToken: authToken,
      user_id: id,
    };
    dispatch(deleteUser({ configdata: config }));
    dispatch(getallUser(user.access_token));
  };
  return (
    <>
      <div>
        <Navbar />
        <Header />
        <div className="wrapper">
          <div className="boxAppBoard boxWhite">
            <div className="boardHeader noMg">
              <div className="boardHeadLeft">
                <h2>Registered Employees</h2>
              </div>
              <div className="boardHeadRight">
                <div className="boardFilter">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="All"
                      checked
                    />
                    <label class="form-check-label">All</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="In Process"
                    />
                    <label class="form-check-label">In Process</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Discrepancies"
                    />
                    <label class="form-check-label">Discrepancies</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Pending"
                    />
                    <label class="form-check-label">Pending</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Approved"
                    />
                    <label class="form-check-label">Approved</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='empAdd'>
                    <h3 className='noMg'>Add Employee </h3>
                    <form className='addEmployee-form' onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-12 col-lg-4'>
                                <div className='fieldWrap'>
                                    <label>Full Name</label>
                                    <input
                                        name="full_name"
                                        type='text'
                                        placeholder='Marvin McKinney'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.full_name}
                                        error={errors.full_name}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-lg-4'>
                                <div className='fieldWrap'>
                                    <label>National Identity Number</label>
                                    <input
                                        name="national_id_number"
                                        type='text'
                                        placeholder='Marvin McKinney'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.national_id_number}
                                        error={errors.national_id_number}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-lg-4'>
                                <div className='fieldWrap'>
                                    <label>Email Address</label>
                                    <input
                                        name="email"
                                        type='text'
                                        placeholder='Marvin McKinney'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        error={errors.email}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-lg-4'>
                                <div className='fieldWrap'>
                                    <label>Phone No.</label>
                                    <input
                                        name="phone"
                                        type='text'
                                        placeholder='(702) 555-0122'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                        error={errors.phone}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-lg-4'>
                                <div className='fieldWrap noLabel'>
                                    <button type='submit' disabled={createLoading}>
                                        {createLoading ?
                                            <>
                                                <div class="text-xs spinner-border text-light" style={{ width: '1rem', height: '1rem' }} role="status">
                                                </div>
                                                <span class="sr-only ml-5"> Loading...</span>
                                            </>
                                            :
                                            "Add Employee"
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div > */}
          <div className="boxAppBoard boxWhite mt-5">
            <div className="boardHeader">
              <div className="boardHeadLeft">
                <h2>All Employees</h2>
              </div>
              <div className="boardHeadRight">
                <div className="searchForm">
                  <input
                    type="text"
                    placeholder="input search text"
                    onChange={handleSearchInputChange}
                    value={searchQuery}
                  />
                  <button>
                    <img src={iconSearch} alt="" />
                  </button>
                </div>
                <div className="datePicker"></div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table">
                <thead className="boardTheadcompany">
                  <tr>
                    <th className="boardCol w-adj2">S.NO.</th>
                    <th className="boardCol w-adj2">User Id</th>
                    <th className="boardCol w-adj2">Name</th>
                    <th className="boardCol w-adj2">Email</th>
                    <th className="boardCol w-adj2">Phone No.</th>
                    <th className="boardCol w-adj2">Create Date</th>
                    <th className="boardCol w-adj2">Status</th>
                    <th className="boardCol w-adj2"></th>
                  </tr>
                </thead>
                <tbody>
                  {fetchLoading ? (
                    <tr>
                      <td className="text-center" colSpan="8">
                        <div className="d-flex justify-content-center my-4">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {!!filteredemployee?.length ? (
                        filteredemployee.map((item, index) => (
                          <tr
                            key={index}
                            className={`boardTrowcompany ${
                              (currentPage === 1
                                ? currentPage
                                : (currentPage - 1) * 10 + 1) <=
                                index + 1 &&
                              index <
                                (currentPage === 1 ? 10 : currentPage * 10)
                                ? ""
                                : "d-none"
                            } `}
                            onClick={() => navigate("/employees/" + item.id)}
                          >
                            <td className="boardCol w-adj2">{index + 1}</td>
                            <td className="boardCol w-adj2">
                              {" "}
                              {`GB-000000${item.id}`}
                            </td>
                            <td className="boardCol w-adj2">
                              <div className="boardTitle widAvatar">
                                <img
                                  className="avatar"
                                  src={item.image || userImagePlaceholder}
                                  alt=""
                                />
                                <strong>{item.full_name}</strong>
                                <span>{item.national_id_number || "--"}</span>
                              </div>
                            </td>
                            <td className="boardCol w-adj2">
                              <a href={`mailto:${item.email}`}>{item.email}</a>
                            </td>
                            <td className="boardCol w-adj2">
                              <a href={`tel:${item.phone}`}>{item.phone}</a>
                            </td>
                            <td className="boardCol w-adj2">
                              {item.created_at?.slice(0, 10)}
                            </td>
                            <td className="boardCol w-adj2">
                              {item.active ? "Completed" : "Process"}
                            </td>
                            <td className="boardCol w-adj2">
                              <img
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  deleteuserdata(item.id);
                                }}
                                src={iconFileDelete}
                                alt=""
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8" className="text-center">
                            <h2 className="text-danger my-4 py-4">
                              Users not Found
                            </h2>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>

            {/* <div className='boardFooter'>
                            <div className='pagination'>
                                <Link className='disabled' to={'/'}><img src={iconCaretLeft} alt='' /></Link>
                                <Link to={'/'}>1</Link>
                                <Link to={'/'}>4</Link>
                                <Link to={'/'}>5</Link>
                                <Link className='active' to={'/'}>6</Link>
                                <Link to={'/'}>7</Link>
                                <Link to={'/'}>8</Link>
                                <Link to={'/'}>50</Link>
                                <Link to={'/'}><img src={iconCaretRight} alt='' /></Link>
                                <Link className='wAuto' to={'/'}>10 / page <img className='ml-6' src={iconCaretDown} alt='' /></Link>
                                <span>Goto</span>
                                <input type='text' />
                            </div>
                        </div> */}
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={getTotalPages}
            />
          </div>
        </div>
      </div>
      <Modal show={showModal} handleClose={close} colWidth={6}>
        <div
          className={[
            classes["modal-content-row-1"],
            "d-flex",
            "align-items-center",
            "justify-content-between",
          ].join(" ")}
        >
          <div className="d-flex align-items-center">
            <div className={classes["profile-pic-wrapper"]}>
              <img alt="Avatar" src={Avatar01} />
            </div>
            <div>
              <p className={classes["user-name"]}>{modalContent.full_name}</p>
              <p className={classes["user-ip"]}>
                {modalContent.national_id_number}
              </p>
            </div>
          </div>
          <div>
            <img src={iconFileDelete} alt="" />
          </div>
        </div>
        <div
          className={[
            classes["modal-content-row-2"],
            "my-4",
            "d-flex",
            "align-items-center",
          ].join(" ")}
        >
          <div>
            <p className={[["mb-0"], classes["personal-info-title"]].join(" ")}>
              Company Name
            </p>
            <p className={[["mb-0"], classes["personal-info-value"]].join(" ")}>
              The Walt Disney Company
            </p>
          </div>
          <div>
            <p className={[["mb-0"], classes["personal-info-title"]].join(" ")}>
              Phone No.
            </p>
            <p className={[["mb-0"], classes["personal-info-value"]].join(" ")}>
              {modalContent.phone}
            </p>
          </div>
          <div>
            <p className={[["mb-0"], classes["personal-info-title"]].join(" ")}>
              Employee Id
            </p>
            <p className={[["mb-0"], classes["personal-info-value"]].join(" ")}>
              GB-4936209
            </p>
          </div>
          <div>
            <p className={[["mb-0"], classes["personal-info-title"]].join(" ")}>
              Registration Date
            </p>
            <p className={[["mb-0"], classes["personal-info-value"]].join(" ")}>
              November 28, 2022
            </p>
          </div>
        </div>
        <div
          className={[
            classes["modal-content-row-3"],
            "d-flex",
            "flex-column",
          ].join(" ")}
        >
          <h4 className={classes["model-content-row-3-title"]}>
            Application Forms
          </h4>
          <div
            className={[
              "d-flex",
              classes["modal-content-row-3-data"],
              "justify-content-between",
            ].join(" ")}
          >
            <div className="d-flex">
              <p className={["mb-0", classes["app-form-title"]].join(" ")}>
                Applied
              </p>
              <p
                className={["mb-0", "mx-2", classes["app-form-value"]].join(
                  " "
                )}
              >
                66
              </p>
            </div>
            <div className="d-flex">
              <p className={["mb-0", classes["app-form-title"]].join(" ")}>
                Pending
              </p>
              <p
                className={["mb-0", "mx-2", classes["app-form-value"]].join(
                  " "
                )}
              >
                66
              </p>
            </div>
            <div className="d-flex">
              <p className={["mb-0", classes["app-form-title"]].join(" ")}>
                In Process
              </p>
              <p
                className={["mb-0", "mx-2", classes["app-form-value"]].join(
                  " "
                )}
              >
                66
              </p>
            </div>
            <div className="d-flex">
              <p className={["mb-0", classes["app-form-title"]].join(" ")}>
                Rejected
              </p>
              <p
                className={["mb-0", "mx-2", classes["app-form-value"]].join(
                  " "
                )}
              >
                66
              </p>
            </div>
            <div className="d-flex">
              <p className={["mb-0", classes["app-form-title"]].join(" ")}>
                Approved
              </p>
              <p
                className={["mb-0", "mx-2", classes["app-form-value"]].join(
                  " "
                )}
              >
                66
              </p>
            </div>
          </div>
        </div>
        <div
          className={[
            classes["modal-content-row-4"],
            "my-4",
            "d-flex",
            "flex-column",
          ].join(" ")}
        >
          <h4 className={classes["model-content-row-4-title"]}>
            Previous Companies
          </h4>
          <div
            className={["d-flex", classes["modal-content-row-4-header"]].join(
              " "
            )}
          >
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-heading"],
                ].join(" ")}
              >
                Company Name
              </p>
            </div>
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-heading"],
                ].join(" ")}
              >
                Employee Id
              </p>
            </div>
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-heading"],
                ].join(" ")}
              >
                From
              </p>
            </div>
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-heading"],
                ].join(" ")}
              >
                To
              </p>
            </div>
          </div>
          <div
            className={["d-flex", classes["modal-content-row-4-data"]].join(
              " "
            )}
          >
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-value"],
                ].join(" ")}
              >
                McDonald's
              </p>
            </div>
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-value"],
                ].join(" ")}
              >
                GB-4936209
              </p>
            </div>
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-value"],
                ].join(" ")}
              >
                October 10, 2020
              </p>
            </div>
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-value"],
                ].join(" ")}
              >
                January 12, 2022
              </p>
            </div>
          </div>
          <div
            className={["d-flex", classes["modal-content-row-4-data"]].join(
              " "
            )}
          >
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-value"],
                ].join(" ")}
              >
                Facebok
              </p>
            </div>
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-value"],
                ].join(" ")}
              >
                GB-4936209
              </p>
            </div>
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-value"],
                ].join(" ")}
              >
                October 10, 2020
              </p>
            </div>
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-value"],
                ].join(" ")}
              >
                January 12, 2022
              </p>
            </div>
          </div>
          <div
            className={["d-flex", classes["modal-content-row-4-data"]].join(
              " "
            )}
          >
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-value"],
                ].join(" ")}
              >
                Mitsubishi
              </p>
            </div>
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-value"],
                ].join(" ")}
              >
                GB-4936209
              </p>
            </div>
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-value"],
                ].join(" ")}
              >
                October 10, 2020
              </p>
            </div>
            <div>
              <p
                className={[
                  "mb-0",
                  classes["modal-content-row-4-table-value"],
                ].join(" ")}
              >
                January 12, 2022
              </p>
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={showDeleteModal} colWidth={4}>
        <div className={classes["delete-modal-wrapper"]}>
          <div className={classes["delete-modal-title"]}>
            <p className={["mb-0", classes["delete-modal-title"]].join(" ")}>
              Are you sure you want to delete?
            </p>
          </div>
          <div
            className={[classes["delete-modal-reason-wrapper"], "mt-5"].join(
              " "
            )}
          >
            <form onSubmit={handleSubmit}>
              <div className={classes["reason-wrapper"]}>
                <div>
                  <label className={classes["reason-label"]}>Give Reason</label>
                </div>
                <div className={classes["reason-field-wrapper"]}>
                  <textarea
                    value={values["delete_message"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete={"false"}
                    id={"delete_message"}
                    name={"delete_message"}
                    rows={5}
                    className={classes["reason-field"]}
                  ></textarea>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-5 mb-2">
                  <button
                    type="submit"
                    className={classes["delete-employee-button"]}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    onClick={closeDeleteModal}
                    className={classes["cancel-button"]}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Modal
        show={showDeleteComfirmModal}
        colWidth={4}
        handleClose={() => {
          setShowDeleteModal(false);
          setShowDeleteComfirmModal(false);
          setFieldError("delete_message", "");
        }}
      >
        <div className={classes["delete-confirm-modal"]}>
          <p
            className={[classes["delete-message"], "text-center", "p-5"].join(
              " "
            )}
          >
            <strong>{selectedUser.full_name}</strong>
            has been deleted
          </p>
        </div>
      </Modal>
    </>
  );
};
export default AppEmployees;
