import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IoChevronBackSharp, IoChevronForwardSharp } from 'react-icons/io5';
import Navbar from '../../navbar';
import Header from '../../header';
import { fetchApplicationWithId, updateApplication } from '../../../store/reducers/applicationSlice';
import FormLoader from '../../forms/formLoader'
import classes from './style.module.css';
import { getstate } from '../../../store/reducers/stateSlice';
import { fetchCategories } from '../../../store/reducers/categoriesSlice';
import { createNotification } from '../../../store/reducers/notificationSlice';
import { setFormFieldData, getFormPageIds, hideAllPagesExceptCurrentFormPage, setInputFieldAndFormPageIds, loadForm, goToFormNextPage, goToFormPreviousPage } from '../../../utility/utility';
import Index from './descripancie';
import { getSignature } from '../../../store/reducers/fileSlice';
const Descrepancies = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const user = useSelector((state) => state.auth.user);
    const authToken = user.access_token;
    const { currentApp } = useSelector((state) => state.applications);
    const accessToken = user.access_token;
    const [formData, setFormData] = useState([]);

    const applicationdata = useSelector((state) => state.applications.currentApp);
    const status = useSelector((state) => state.status.status);

    const [pageIds, setPageIds] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoaded, setIsLoaded] = useState(false);
    const [formIds, setFormIds] = useState([]);
    const [selectedFieldData, setSelectedFieldData] = useState({})
    const [signatureImage, setSignatureImage] = useState('');

    async function statusvalue() {
        const data = {
            accessToken: accessToken,
            id: params.id,
        };
        dispatch(fetchApplicationWithId(data));
        dispatch(getstate(accessToken));
        dispatch(fetchCategories({ accessToken: accessToken }))

    };

    const selecteddata = async (e) => {
        const filterstatus = await status.filter((item) => item.name === e);
        console.log(filterstatus, 'llllllllllllllllllllllllllllllllllll')
        await console.log(e)
        if (e === 'approved') {
            // setIsOpen(true);
        }
        const currentTime = new Date().toLocaleTimeString();
        await dispatch(
            updateApplication({
                accessToken,
                id: params.id,
                state: filterstatus ? filterstatus[0].id : '',
                currentTime,
            })
        );
        dispatch(createNotification({
            message: `Your Application ${e}`,
            user_id: user.user.id,
            accessToken: authToken,
            is_admin: false
        }))
        statusvalue();
    };



    useEffect(() => {
        statusvalue();
    }, [dispatch]);


    useEffect(() => {
        const runAsyncFunctions = async () => {
            if (isLoaded) {
                await setFormFieldData(applicationdata.json_data);
                let userId = applicationdata.user_id
                let signature = await getSignature({ userId: userId, applicationId: params.id, authToken });
                setSignatureImage(signature);
            }
        };

        runAsyncFunctions();
    }, [isLoaded]);

    useEffect(() => {
        if (pageIds) {
            hideAllPagesExceptCurrentFormPage(pageIds, currentPage);
        }
    }, [pageIds])

    useEffect(() => {
        attachEventHandlers();
        // Cleanup event listeners on unmount
        return () => {
            formIds.forEach((id) => {
                const inputField = document.getElementById(id);
                if (inputField) {
                    inputField.removeEventListener('click', handleInputChange);
                }
            });
        };
    }, [formIds, isLoaded]);

    useEffect(() => {
        if (applicationdata?.discrepancies) {
            setSelectedFieldData(applicationdata.discrepancies)
        }
    }, [applicationdata])
    //   const handleInputChange = (event) => {
    //     if(event.target.style.backgroundColor==="rgb(255, 0, 0)"){
    //         setSelectedFieldData((prevFields) => prevFields.filter((id) => id !== event.target.id));
    //         event.target.style.backgroundColor = '';
    //     }else{
    //         event.target.style.backgroundColor = '#ff0000'; // Change background color to red
    //         setSelectedFieldData((prevFields) => [...prevFields, "dis_"+event.target.id]);
    //     }
    //   };
    const handleInputChange = (event) => {
        const fieldId = "dis_" + event.target.id;

        setSelectedFieldData((prevFields) => {
            // Create a copy of the previous fields
            const newFields = { ...prevFields };

            console.log("fieldId:", fieldId);
            console.log("newFields:", newFields);

            if (newFields[fieldId]) {
                // If the fieldId exists, remove it from the object
                console.log("Removing color and field:", fieldId);
                delete newFields[fieldId]; // Remove the entire entry
                event.target.style.backgroundColor = '';
            } else {
                // If the fieldId doesn't exist, add it
                console.log("Adding field:", fieldId);
                newFields[fieldId] = { value: '', check: true }; // Initialize with empty value and checked
                event.target.style.backgroundColor = '#ff0000'; // Change background color to red
            }

            // Return the updated fields
            return newFields;
        });
    };

    // Function to attach event handlers
    const attachEventHandlers = () => {
        formIds.forEach((id) => {
            const inputField = document.getElementById(id);
            if (inputField) {
                inputField.addEventListener('click', handleInputChange);
            }
        });
    };
    useEffect(() => {
        if (formIds && signatureImage && isLoaded) {
            formIds.forEach(id => {
                const inputField = document.getElementById(id);
                if (inputField.type === 'image') {
                    let originalWidth = window.getComputedStyle(inputField).width.replace("px", "")
                    let originalTop = window.getComputedStyle(inputField).top.replace("px", "")
                    let height = (originalWidth * 114) / 179;
                    inputField.style.height = height + "px";
                    inputField.style.top = (originalTop - height) + 20 + "px";
                    inputField.src = signatureImage;
                    inputField.style.objectFit = 'contain'; // Option
                    inputField.style.pointerEvents = 'none'; // Makes the input non-clickable
                }
            });

        }
    }, [signatureImage, isLoaded.formIds])


    return (
        <div>
            <Navbar />
            <Header />
            <div className="wrapper">
                <div className="boxAppBoard boxWhite">
                    <div className="stickyFormHeader">
                        <div className="AppFormBar noShadow">
                            <div className="title">
                                {currentApp?.form} / {currentApp?.name} / {formData.find((el) => el.id === currentPage)?.pageName}
                            </div>
                            <div className='pagination'>
                                <div className='pageNum d-flex align-items-center'>
                                    <IoChevronBackSharp onClick={() => goToFormPreviousPage(currentPage, setCurrentPage)} />
                                    <span className='pageCurrent d-flex align-items-center justify-content-center' style={{ width: 50, height: 50, border: 0, textAlign: 'center', color: '#fff' }} >{currentPage}</span> <span className='slash'>{`/`}<span style={{ marginLeft: 10 }}></span></span>{pageIds.length}
                                    <IoChevronForwardSharp onClick={() => goToFormNextPage(currentPage, pageIds, setCurrentPage)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="boardHeader">
                        <div className="boardHeadLeft">
                            <h2>Descrepancies</h2>
                        </div>
                        <div className="boardHeadRight">
                            <div>
                                <select
                                    className="form-select"
                                    value={applicationdata?.state || 'All'}
                                    onChange={(e) => {
                                        selecteddata(e.target.value);
                                    }}
                                >
                                    <option disabled value="All">
                                        Select State
                                    </option>
                                    {status.length > 0 &&
                                        status
                                            .filter(item =>
                                                ['in_process', 'pending_from_dw', 'discrepancies', 'approved', 'renewal'].includes(item.name)
                                            )
                                            .map(item => (
                                                <option key={item.name} value={item.name}>
                                                    {item.name === 'in_process' ? 'In Process' :
                                                        item.name === 'pending_from_dw' ? 'Pending from DW' :
                                                            item.name === 'discrepancies' ? 'Discrepancies' :
                                                                item.name === 'approved' ? 'Approved' :
                                                                    item.name === 'renewal' ? 'Renewal' : ''}
                                                </option>
                                            ))
                                    }
                                </select>

                            </div>

                        </div>
                    </div>
                    <div className={classes['board-content']}>
                        <div className='disarea'>
                            <Index selectedFieldData={selectedFieldData} setSelectedFieldData={setSelectedFieldData} formId={params.id} />
                            <FormLoader applicationName={applicationdata.name} setFormIds={setFormIds} setPageIds={setPageIds} setIsLoaded={setIsLoaded} />
                        </div>

                    </div>
                </div>
            </div>




        </div>
    );
};

export default Descrepancies;