import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useParams } from 'react-router-dom';
import { IoChevronBackSharp, IoChevronForwardSharp } from 'react-icons/io5';
import Navbar from '../../navbar';
import Header from '../../header';
import { fetchApplicationWithId, updateApplication } from '../../../store/reducers/applicationSlice';
import classes from './style.module.css';
import { getstate } from '../../../store/reducers/stateSlice';
import { fetchCategories } from '../../../store/reducers/categoriesSlice';
import { createNotification } from '../../../store/reducers/notificationSlice';
import {setFormFieldData,getFormPageIds,hideAllPagesExceptCurrentFormPage,setInputFieldAndFormPageIds,loadForm,goToFormNextPage,goToFormPreviousPage} from '../../../utility/utility';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import { getSignature } from '../../../store/reducers/fileSlice';
import FormLoader from '../../forms/formLoader';
const ViewApp = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const user = useSelector((state) => state.auth.user);
    const authToken = user.access_token;
    const { currentApp } = useSelector((state) => state.applications);
    const accessToken = user.access_token;
    const [formData, setFormData] = useState([]);
    const applicationdata = useSelector((state) => state.applications.currentApp);
    const status = useSelector((state) => state.status.status);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [formIds, setFormIds] = useState([]);
    const [pageIds,setPageIds] = useState([]);
    const [currentPage,setCurrentPage] = useState(1);
    const [isLoaded, setIsLoaded] = useState(false);
    const [expiryDate, setExpiryDate] = useState(new Date());
    const [signatureImage,setSignatureImage] = useState('');


    
    const handleChange = (date) => {
      setExpiryDate(date); // Update the date in the parent component
    };
  
    async function statusvalue() {
        const data = {
            accessToken: accessToken,
            id: params.id,
        };
        dispatch(fetchApplicationWithId(data));
        dispatch(getstate(accessToken));
        dispatch(fetchCategories({ accessToken: accessToken }))
       
    };

    const selecteddata = async (e) => {
        const filterstatus = await status.filter((item) => item.name === e);
        console.log(filterstatus, 'llllllllllllllllllllllllllllllllllll')
        let dueDate = null;
        await console.log(e)
        if (e === 'approved') {
            setIsOpen(true);
            // setOpenDatePicker(true);
            console.log(expiryDate);
            const currentDateTime = new Date();
            dueDate = currentDateTime.toISOString().split('T')[0];
        }


      
        await dispatch(
            updateApplication({
                accessToken,
                id: params.id,
                state: filterstatus ? filterstatus[0].id : '',
                dueDate
                        })
        );
        dispatch(createNotification({
            message: `Your Application ${e}`,
            user_id: user.user.id,
            accessToken: authToken,
            is_admin: false
        }))
        statusvalue();
    };

    

    useEffect(() => {
        statusvalue();
    }, [dispatch]);
    
    useEffect(()=>{
        console.log(expiryDate);
    },[expiryDate])


    useEffect(() => {
        const runAsyncFunctions = async () => {
            if (isLoaded) {
                // Ensure setInputFieldAndFormPageIds runs first
                
                // Ensure hideAllPagesExceptCurrentFormPage runs after the first function
                // await hideAllPagesExceptCurrentFormPage(pageIds, currentPage);
                
                // Ensure setFormFieldData runs after the second function
                console.log(applicationdata)
                await setFormFieldData(applicationdata.json_data);
                let userId = applicationdata.user_id
                let signature =await getSignature({userId:userId,applicationId:params.id,authToken});
                setSignatureImage(signature);
            }
        };
    
        runAsyncFunctions();
    }, [isLoaded]);

    useEffect(()=>{
        if(pageIds){
            hideAllPagesExceptCurrentFormPage(pageIds, currentPage);
        }
    },[pageIds])

    useEffect(()=>{
        if(formIds && signatureImage && isLoaded){
            formIds.forEach(id => {
              const inputField = document.getElementById(id);
              if(inputField.type === 'image'){
                let originalWidth = window.getComputedStyle(inputField).width.replace("px","")
                let originalTop = window.getComputedStyle(inputField).top.replace("px","")
                let height = (originalWidth*114)/179;
                inputField.style.height =height+"px";
                inputField.style.top = (originalTop-height)+20+"px";
                inputField.src = signatureImage;
                inputField.style.objectFit = 'contain'; // Option
                inputField.style.pointerEvents = 'none'; // Makes the input non-clickable
              }
            });

        }
    },[signatureImage,isLoaded,formIds])
    
    return (
        <div>
            <Navbar />
            <Header />
            <div className="wrapper">
                <div className="boxAppBoard boxWhite">
                <div className="stickyFormHeader">

                    <div className="AppFormBar noShadow">
                        <div className="title">
                            {currentApp?.form} / {currentApp?.name} / {formData.find((el) => el.id === currentPage)?.pageName}
                        </div>
                        <div className='pagination'>
                        <div className='pageNum d-flex align-items-center'>
                            <IoChevronBackSharp onClick={()=>goToFormPreviousPage(currentPage,setCurrentPage)} />
                            <span className='pageCurrent d-flex align-items-center justify-content-center' style={{ width: 50, height: 50, border:0, textAlign:'center', color:'#fff' }} >{currentPage}</span> <span className='slash'>{`/`}<span style={{ marginLeft: 10 }}></span></span>{pageIds.length}
                            <IoChevronForwardSharp onClick={()=>goToFormNextPage(currentPage,pageIds,setCurrentPage)} />
                        </div>
                    </div>
                    </div>
                </div>
                    <div className="boardHeader">
                        <div className="boardHeadLeft">
                            <h2>In Process Applications</h2>
                        </div>
                        <div className="boardHeadRight">
                            <div>
                                <select
                                    className="form-select"
                                    value={applicationdata?.state || 'All'}
                                    onChange={(e) => {
                                        selecteddata(e.target.value);
                                    }}
                                >
                                    <option disabled value="All">
                                        Select State
                                    </option>
                                    {status.length > 0 &&
    status.map((item) => {
        const displayText = 
            item.name === 'in_process' ? 'In Process' :
            item.name === 'pending_from_dw' ? 'Pending from DW' :
            item.name === 'discrepancies' ? 'Discrepancies' :
            item.name === 'approved' ? 'Approved' :
            item.name === 'rejected' ? 'Rejected' :
            item.name === 'renewal' ? 'Renewal' :
            null; // Use null for unmatched cases

        return displayText ? (
            <option key={item.name} value={item.name}>
                {displayText}
            </option>
        ) : null; // Return null for unmatched cases
    })
}
                                </select>
                            </div>

                        </div>
                    </div>
                
                    <div className={classes['board-content']}>     
                    <FormLoader applicationName={applicationdata.name} setFormIds={setFormIds} setPageIds={setPageIds} setIsLoaded={setIsLoaded}/>
                    </div>
                 
                </div>    
            </div>




        </div>
    );
};

export default ViewApp;
